<template>
  <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
    <template v-if="internetConnection">
      <loader-page v-if="isLoading"></loader-page>
      <template v-else>
        <!-- Header -->
        <div class="uk-grid-small" uk-grid>
          <div class="uk-width-1-4">
            <div class="uk-flex uk-width-medium">
              <button
                  id="filter"
                  type="button"
                  class="uk-button uk-button-default uk-width-1-1"
                  style="background: white;"
              >Filter</button>
              <button
                  v-if="filterText != 0"
                  class="uk-button uk-button-small uk-button-danger uk-width-auto"
                  type="button"
                  @click="resetFilter"
              ><span uk-icon="icon: close"></span></button>
            </div>
            <div id="dropdown-filter" class="uk-width-auto uk-margin-remove-top" uk-dropdown='mode: click; toggle: #filter'>
              <form @submit.prevent="setFilter">
                <div class="uk-dropdown-grid" uk-grid>
                  <div>
                    <ul class="uk-nav uk-dropdown-nav">
                      <div class="uk-margin">
                        <label class="uk-form-label" for="formFilterFullname">Fullname</label>
                        <div class="uk-form-controls">
                          <input
                              id="formFilterFullname"
                              class="uk-input"
                              type="text"
                              v-model="formFilter.fullname"
                          >
                        </div>
                      </div>
                      <div class="uk-margin">
                        <label class="uk-form-label" for="formFilterPhoneNumber">Phone Number</label>
                        <div class="uk-form-controls">
                          <input
                              id="formFilterPhoneNumber"
                              class="uk-input"
                              type="text"
                              v-model="formFilter.phone_number"
                          >
                        </div>
                      </div>
                      <div class="uk-margin">
                        <label class="uk-form-label" for="formFilterEmail">Email</label>
                        <div class="uk-form-controls">
                          <input
                              id="formFilterEmail"
                              class="uk-input"
                              type="email"
                              v-model="formFilter.email"
                          >
                        </div>
                      </div>
                    </ul>
                  </div>
                  <div>
                    <ul class="uk-nav uk-dropdown-nav">
                      <div class="uk-margin">
                        <label><input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="formFilter.educations"
                        > Pendidikan</label>
                      </div>
                      <div class="uk-margin">
                        <label><input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="formFilter.work_experiences"
                        > Pengalaman Kerja</label>
                      </div>
                      <div class="uk-margin">
                        <label><input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="formFilter.skills"
                        > Keterampilan</label>
                      </div>
                    </ul>
                  </div>
                  <div>
                    <ul class="uk-nav uk-dropdown-nav">
                      <div class="uk-margin">
                        <label><input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="formFilter.doc_skck"
                        > SKCK</label>
                      </div>
                      <div class="uk-margin">
                        <label><input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="formFilter.doc_ktp"
                        > KTP</label>
                      </div>
                      <div class="uk-margin">
                        <label><input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="formFilter.doc_sim"
                        > SIM</label>
                      </div>
                      <div class="uk-margin">
                        <label><input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="formFilter.doc_ijazah"
                        > Ijazah</label>
                      </div>
                    </ul>
                  </div>
                  <div>
                    <ul class="uk-nav uk-dropdown-nav">
                      <div class="uk-margin">
                        <label><input
                            class="uk-checkbox"
                            type="checkbox"
                            v-model="formFilter.kormo"
                        > Kormo</label>
                      </div>
                    </ul>
                  </div>
                </div>
                <div>
                  <button
                      id="filter"
                      type="submit"
                      class="uk-button uk-width-expand"
                      :class="[disabledCandidateFilterSearchButton ? 'uk-button-default' : 'uk-button-primary']"
                      :disabled="disabledCandidateFilterSearchButton"
                  >Search</button>
                </div>
              </form>
            </div>
          </div>
          <div class="uk-width-1-5">
            <button
                v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])"
                type="button"
                class="uk-button uk-button-primary uk-width-1-1"
                @click="showCreateCandidateModalBox"
            >Create</button>
          </div>
          <div class="uk-width-1-4 uk-flex uk-flex-between">
            <button
              class="uk-button uk-button-primary"
              @click="showUploadTemplateModalBox"
            >
              <img :src="`${images}/outline-upload.svg`" alt="Upload icon" width="20" />
              Upload Template
            </button>
            <button
              class="uk-button uk-button-default"
              @click="doDownloadTemplate"
              style="border: 1px solid #0275D8; color: #0275D8"
            >
              <img :src="`${images}/download-outline-blue.svg`" alt="Download icon" width="20" />
              Download Template
            </button>
          </div>
          <div class="uk-width-1-4"></div>
        </div>
        <!-- End header -->

        <!-- Table list -->
        <div class="uk-card uk-card-default uk-margin-top">
          <div class="uk-overflow-auto">
            <table class="uk-table uk-table-small uk-table-striped">
              <thead>
              <tr>
                <th class="uk-text-nowrap uk-text-center">Fullname</th>
                <th class="uk-text-nowrap uk-text-center">Email</th>
                <th class="uk-text-nowrap uk-text-center">Phone</th>
                <th class="uk-text-nowrap uk-text-center">Pendidikan</th>
                <th class="uk-text-nowrap uk-text-center">Pengalaman Kerja</th>
                <th class="uk-text-nowrap uk-text-center">Keterampilan</th>
                <th class="uk-text-nowrap uk-text-center">Dokumen Pendukung</th>
                <th class="uk-text-nowrap uk-text-center">Kormo</th>
                <th class="uk-text-nowrap uk-text-center">Updated By</th>
                <th class="uk-text-nowrap uk-text-center uk-width-small">Action</th>
              </tr>
              </thead>
              <loading v-if="candidateTableLoading" :colspan="10"></loading>
              <template v-else>
                <tbody v-if="candidateData.docs.length > 0">
                <tr
                    v-for="(candidate, index) in candidateData.docs"
                    :key="index"
                >
                  <td class="uk-text-nowrap uk-text-center">{{ candidate.fullname || '-' }}</td>
                  <td class="uk-text-nowrap uk-text-center">{{ candidate.email || '-' }}</td>
                  <td class="uk-text-nowrap uk-text-center">{{ candidate.phone_number || '-' }}</td>
                  <td class="uk-text-nowrap uk-text-center">
                    <img :src="`${images}/check-true.svg`" style="width:20px" v-if="candidate.educations">
                    <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                  </td>
                  <td class="uk-text-nowrap uk-text-center">
                    <img :src="`${images}/check-true.svg`" style="width:20px" v-if="candidate.work_experiences">
                    <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                  </td>
                  <td class="uk-text-nowrap uk-text-center">
                    <img :src="`${images}/check-true.svg`" style="width:20px" v-if="candidate.skills">
                    <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                  </td>
                  <td class="uk-text-nowrap uk-text-center">{{ candidate.doc_count || 0 }}/4</td>
                  <td class="uk-text-nowrap uk-text-center">
                    <img :src="`${images}/check-true.svg`" style="width:20px" v-if="candidate.kormo_id">
                    <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                  </td>
                  <td class="uk-text-nowrap uk-text-center">{{ candidate.updated_by || '-' }}</td>
                  <td class="uk-text-nowrap uk-text-center uk-width-small uk-flex uk-flex-column" style="gap: 10px;">
                    <button
                        type="button"
                        class="uk-button uk-button-small uk-button-default"
                        style="background: #333747; color: white;"
                        v-clipboard:copy="candidate._id"
                        v-clipboard:success="onCopy"
                    >Copy Id</button>
                    <button
                        v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])"
                        type="button"
                        class="uk-button uk-button-small uk-button-default"
                        @click="candidateDetail({ id: candidate._id })"
                    >Detail</button>
                    <button
                        v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])"
                        type="button"
                        class="uk-button uk-button-small uk-button-danger"
                        @click="showDeleteCandidateModalBox({
                                                    id: candidate._id,
                                                    fullname: candidate.fullname,
                                                    email: candidate.email,
                                                    phoneNumber: candidate.phone_number
                                                })"
                    >Delete</button>
                    <button
                        v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])"
                        type="button"
                        class="uk-button uk-button-small uk-button-success"
                        @click="showInviteCandidateModalBox({ id: candidate._id })"
                    >Invite</button>
                  </td>
                </tr>
                </tbody>
                <empty-table v-else :colspan="10" />
              </template>
            </table>
          </div>
          <pagination
              :total-data="candidateData.totalDocs"
              :limit="meta.limit"
              :change-limit="changeLimit"
              :change-page="changePage"
          ></pagination>
        </div>
        <!-- End table list -->

        <!-- Create candidate modal box -->
        <div id="create-candidate-modal-box" class="uk-flex-top create-candidate-modal-box" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="resetSelectedUser" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">User Form</h2>
            </div>
            <form @submit.prevent="doCreateCandidate">
              <div class="uk-modal-body">
                <div class="uk-grid-small" uk-grid>
                  <div class="uk-width-1-1">
                    <label for="fullname" class="uk-form-label">Full Name <span class="uk-text-danger">*</span></label>
                    <input
                      id="fullname"
                      type="text"
                      class="uk-input uk-border-rounded"
                      placeholder="Full Name"
                      v-model="selectedUser.fullname"
                      data-vv-name="fullname"
                      data-vv-scope="selectedUser"
                      v-validate="{ required: true }"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedUser.fullname')">{{ errors.first('selectedUser.fullname') }}</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="loginVia" class="uk-form-label">Login via: </label>
                    <label id="loginVia" for="loginViaEmail" class="uk-margin-small-left">
                      <input
                        id="loginViaEmail"
                        type="radio"
                        class="uk-radio"
                        v-bind:value="true"
                        v-model="selectedUser.login_via_email"
                      >
                      <span> Email</span>
                    </label>
                    <label id="loginVia" for="loginViaPhoneNumber" class="uk-margin-small-left">
                      <input
                        id="loginViaPhoneNumber"
                        type="radio"
                        class="uk-radio"
                        v-bind:value="false"
                        v-model="selectedUser.login_via_email"
                      >
                      <span> Phone number</span>
                    </label>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="email" class="uk-form-label">Email <span v-if="selectedUser.login_via_email" class="uk-text-danger">*</span></label>
                    <input
                      id="email"
                      type="email"
                      class="uk-input uk-border-rounded"
                      placeholder="Email"
                      v-model="selectedUser.email"
                      data-vv-name="email"
                      data-vv-scope="selectedUser"
                      v-validate="{ required: selectedUser.login_via_email, email: true }"
                      @input="findCandidateByEmail"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedUser.email')">
                      {{ errors.first('selectedUser.email') }} Switch login mode to make this field optional.
                    </span>
                    <span class="uk-text-small uk-text-danger" v-show="emailAlreadyExistValidator">Email already exist</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="phoneNumber" class="uk-form-label">Phone Number <span v-if="!selectedUser.login_via_email" class="uk-text-danger">*</span></label>
                    <input
                      id="phoneNumber"
                      type="text"
                      class="uk-input uk-border-rounded"
                      placeholder="Phone Number"
                      v-model="selectedUser.phone_number"
                      data-vv-name="phone_number"
                      data-vv-scope="selectedUser"
                      v-validate="{ required: !selectedUser.login_via_email }"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedUser.phone_number')">
                      {{ errors.first('selectedUser.phone_number') }} Switch login mode to make this field optional.
                    </span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="password" class="uk-form-label">Password</label>
                    <input
                      id="password"
                      type="password"
                      class="uk-input uk-border-rounded"
                      placeholder="Password"
                      v-model="selectedUser.password"
                    >
                    <p class="uk-text-small uk-margin-remove-bottom">Password must consists of:</p>
                    <ul class="uk-margin-remove-top">
                      <li>
                        <p 
                          class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove"
                        >
                          {{ 'Minimum 8 characters' }}
                          <img v-if="passwordErrors.minimalChars" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                          <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                        </p>
                      </li>
                      <li>
                        <p class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove">
                          {{ '1 uppercase letter (A-Z)' }}
                          <img v-if="passwordErrors.hasUpperCase" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                          <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                        </p>
                      </li>
                      <li>
                        <p class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove">
                          {{ '1 lowercase letter (a-z)' }}
                          <img v-if="passwordErrors.hasLowerCase" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                          <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="birthplace" class="uk-form-label">Birthplace <span class="uk-text-danger">*</span></label>
                    <multiselect
                      id="birthplace"
                      placeholder="Type to search city name"
                      label="name"
                      track-by="_id"
                      :multiple="false"
                      :close-on-select="true"
                      :searchable="true"
                      :internal-search="false"
                      :options-limit="50"
                      :options="cityOptions"
                      v-model="selectedUser.birthplace_city_id"
                      @search-change="setCityOptions"
                      data-vv-name="birthplace_city"
                      data-vv-scope="selectedUser"
                      v-validate="{ required: true }"
                    ></multiselect>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedUser.birthplace_city')">{{ errors.first('selectedUser.birthplace_city') }}</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="birthdate" class="uk-form-label">Birthdate <span class="uk-text-danger">*</span></label>
                    <datetime
                      id="birthdate"
                      type="date"
                      input-class="uk-input uk-border-rounded"
                      placeholder="dd/mm/yy"
                      v-model="selectedUser.birthdate"
                      data-vv-name="birthdate"
                      data-vv-scope="selectedUser"
                      v-validate="{ required: true }"
                    ></datetime>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedUser.birthdate')">{{ errors.first('selectedUser.birthdate') }}</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="city" class="uk-form-label">City <span class="uk-text-danger">*</span></label>
                    <multiselect
                      id="city"
                      placeholder="Type to search city name"
                      label="name"
                      track-by="_id"
                      :multiple="false"
                      :close-on-select="true"
                      :searchable="true"
                      :internal-search="false"
                      :options-limit="50"
                      :options="cityOptions"
                      v-model="selectedUser.domicile_city_id"
                      @search-change="setCityOptions"
                      data-vv-name="domicile"
                      data-vv-scope="selectedUser"
                      v-validate="{ required: true }"
                    ></multiselect>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedUser.domicile')">{{ errors.first('selectedUser.domicile') }}</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="address" class="uk-form-label">Address <span class="uk-text-danger">*</span></label>
                    <input
                      id="address"
                      type="text"
                      class="uk-input uk-border-rounded"
                      placeholder="Address"
                      v-model="selectedUser.address"
                      data-vv-name="address"
                      data-vv-scope="selectedUser"
                      v-validate="{ required: true }"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedUser.address')">{{ errors.first('selectedUser.address') }}</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="aboutMe" class="uk-form-label">About Me</label>
                    <textarea
                      id="aboutMe"
                      rows="5"
                      class="uk-textarea uk-border-rounded"
                      style="resize: none;"
                      placeholder="About Me"
                      v-model="selectedUser.description"
                    ></textarea>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="jobPreference" class="uk-form-label">Job Preferences</label>
                    <multiselect
                      id="jobPreference"
                      placeholder="Type to search job preference name"
                      label="name"
                      track-by="_id"
                      :multiple="true"
                      :close-on-select="false"
                      :searchable="true"
                      :internal-search="true"
                      :options="jobPreferenceOptions"
                      v-model="selectedUser.job_preference_ids"
                    ></multiselect>
                  </div>
                  <div v-show="false" class="uk-width-1-1">
                    <label for="role" class="uk-form-label">Role <span class="uk-text-danger">*</span></label>
                    <multiselect
                      id="role"
                      placeholder="Select role"
                      label="name"
                      track-by="_id"
                      :multiple="false"
                      :close-on-select="true"
                      :searchable="true"
                      :internal-search="true"
                      :options="roleOptions"
                      v-model="selectedUser.roles"
                      data-vv-name="role"
                      data-vv-scope="selectedUser"
                      v-validate="{ required: false }"
                    ></multiselect>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedUser.role')">{{ errors.first('selectedUser.role') }}</span>
                  </div>
                  <div v-if="selectedUser.roles && selectedUser.roles.name === 'Candidate'" class="uk-width-1-1">
                    <label for="partners" class="uk-form-label">Partners</label>
                    <multiselect
                      id="partners"
                      placeholder="Type to search company partner"
                      label="name"
                      track-by="name"
                      :multiple="true"
                      :close-on-select="false"
                      :searchable="true"
                      :internal-search="false"
                      :options="partnerOptions"
                      :options-limit="50"
                      v-model="selectedUser.partner_ids"
                      @search-change="setPartnerOptions"
                    ></multiselect>
                  </div>
                  <div v-if="selectedUser.roles && selectedUser.roles.name === 'Employer'" class="uk-width-1-1">
                    <label for="company" class="uk-form-label">Company</label>
                    <multiselect
                      id="company"
                      placeholder="Type to search company"
                      label="name"
                      track-by="name"
                      :multiple="false"
                      :close-on-select="true"
                      :searchable="true"
                      :internal-search="false"
                      :options="companyOptions"
                      :options-limit="50"
                      v-model="selectedUser.company_id"
                      @search-change="setCompanyOptions"
                    ></multiselect>
                  </div>
                  <div v-if="selectedUser.roles && selectedUser.roles.name === 'Employer'" class="uk-width-1-1">
                    <label for="companyRole" class="uk-form-label">Company Role</label>
                    <multiselect
                      id="companyRole"
                      placeholder="Type to search company role"
                      label="name"
                      track-by="name"
                      :multiple="false"
                      :close-on-select="true"
                      :searchable="true"
                      :internal-search="false"
                      :options="companyRoleOptions"
                      :options-limit="50"
                      v-model="selectedUser.client_role_id"
                      @search-change="setCompanyRoleOptions"
                    ></multiselect>
                  </div>
                  <div v-if="selectedUser.roles && selectedUser.roles.name === 'Employer'" class="uk-width-1-1">
                    <label for="companyOffice" class="uk-form-label">Office (property)</label>
                    <multiselect
                      id="companyOffice"
                      placeholder="Type to search property"
                      label="name"
                      track-by="name"
                      :multiple="false"
                      :close-on-select="true"
                      :searchable="true"
                      :internal-search="false"
                      :options="companyOfficeOptions"
                      :options-limit="50"
                      v-model="selectedUser.client_office_id"
                      @search-change="setCompanyOfficeOptions"
                    ></multiselect>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="notes" class="uk-form-label">Notes</label>
                    <input
                      id="notes"
                      type="text"
                      class="uk-input uk-border-rounded"
                      placeholder="Notes"
                      v-model="selectedUser.notes_superadmin"
                    >
                  </div>
                  <div class="uk-width-1-1">
                    <label for="userVerified" class="uk-form-label">
                      <input
                        id="userVerified"
                        type="checkbox"
                        class="uk-checkbox"
                        v-model="selectedUser.user_verified"
                      >
                      <span> User Verified</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                <button
                  v-if="loadingButtonModalBox"
                  type="button"
                  class="uk-button uk-button-default"
                  disabled
                >
                  <span uk-spinner="ratio: 0.5"></span>
                  <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                  v-else
                  type="submit"
                  class="uk-button"
                  :class="[disabledCreateOrEditCandidateSaveButton ? 'uk-button-default' : 'uk-button-primary']"
                  :disabled="disabledCreateOrEditCandidateSaveButton"
                >Save</button>
              </div>
            </form>
          </div>
        </div>
        <!-- End create candidate modal box -->

        <!-- Delete candidate modal box -->
        <div id="delete-candidate-modal-box" class="uk-flex-top delete-candidate-modal-box" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="resetSelectedUser" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Delete User</h2>
            </div>
            <div class="uk-modal-body">
              <div class="uk-grid-small" uk-grid>
                <div class="uk-width-1-1">
                  <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-4">
                      <p>Name: </p>
                    </div>
                    <div class="uk-width-3-4 uk-text-break">
                      <p class="uk-text-bold">{{ selectedUser.fullname || '-' }}</p>
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-4">
                      <p>Email: </p>
                    </div>
                    <div class="uk-width-3-4 uk-text-break">
                      <p class="uk-text-bold">{{ selectedUser.email || '-' }}</p>
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-4">
                      <p>Phone Number: </p>
                    </div>
                    <div class="uk-width-3-4 uk-text-break">
                      <p class="uk-text-bold">{{ selectedUser.phone_number || '-' }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button
                  v-if="loadingButtonModalBox"
                  type="button"
                  class="uk-button uk-button-default"
                  disabled
              >
                <span uk-spinner="ratio: 0.5"></span>
                <span class="uk-margin-small-left">Loading</span>
              </button>
              <button
                  v-else
                  type="button"
                  class="uk-button uk-button-danger"
                  @click="doDeleteCandidate"
              >Delete</button>
            </div>
          </div>
        </div>
        <!-- End delete candidate modal box -->

        <!-- Invite candidate modal box -->
        <div id="invite-candidate-modal-box" class="uk-flex-top invite-candidate-modal-box" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="resetInviteGroupForm" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Invite</h2>
            </div>
            <form @submit.prevent="doInviteCandidate">
              <div class="uk-modal-body">
                <div class="uk-grid-small" uk-grid>
                  <div class="uk-width-1-1">
                    <label for="inviteUserToGroup" class="uk-form-label">Invite user to group <span class="uk-text-danger">*</span></label>
                    <multiselect
                        id="inviteUserToGroup"
                        placeholder="Select group"
                        label="name"
                        track-by="name"
                        :multiple="false"
                        :close-on-select="true"
                        :searchable="true"
                        :internal-search="false"
                        :options-limit="50"
                        :options="groupOptions"
                        @search-change="setGroupOptions"
                        data-vv-name="group_id"
                        data-vv-scope="inviteGroupForm"
                        v-model="inviteGroupForm.group_id"
                        v-validate="{
                                                required: true
                                            }"
                    ></multiselect>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('inviteGroupForm.group_id')">{{ errors.first('inviteGroupForm.group_id') }}</span>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                <button
                    v-if="loadingButtonModalBox"
                    type="button"
                    class="uk-button uk-button-default"
                    disabled
                >
                  <span uk-spinner="ratio: 0.5"></span>
                  <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                    v-else
                    type="submit"
                    class="uk-button"
                    :class="[disabledInviteGroupSaveButton ? 'uk-button-default' : 'uk-button-primary']"
                    :disabled="disabledInviteGroupSaveButton"
                >Save</button>
              </div>
            </form>
          </div>
        </div>
        <!-- End invite candidate modal box -->

        <!-- Upload template modal box -->
        <div id="upload-template-modal-box" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="hideUploadTemplateModalBox" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Upload Template</h2>
            </div>
            <div class="uk-modal-body">
              <div class="uk-grid-small" uk-grid>
                <div class="uk-width-1-1">
                  <label for="team" class="uk-form-label">Choose which team this template from</label>
                  <multiselect
                    id="team"
                    placeholder="Select team"
                    :searchable="false"
                    :internal-search="false"
                    :options="teamOptions"
                    v-model="uploadedTemplateTeam"
                  ></multiselect>
                </div>
              </div>
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button
                type="button"
                class="uk-button uk-button-default uk-margin-small-right"
                :disabled="loadingButtonModalBox"
                style="border: 1px solid #0275D8; color: #0275D8"
                @click="hideUploadTemplateModalBox"
              >Cancel</button>
              <button
                v-if="loadingButtonModalBox"
                type="button"
                class="uk-button uk-button-default"
                disabled
              >
                <span uk-spinner="ratio: 0.5"></span>
                <span class="uk-margin-small-left">Loading</span>
              </button>
              <button
                v-else
                type="button"
                class="uk-button"
                :class="[uploadedTemplateTeam ? 'uk-button-primary' : 'disabled-button']"
                :disabled="!uploadedTemplateTeam"
                @click="triggerUploadTemplate"
              >
                Choose File
                <input
                  name="templateFile"
                  type="file"
                  ref="templateFile"
                  accept=".xls,.xlsx"
                  style="display: none;"
                  @change="doUploadTemplate"
                >
              </button>
            </div>
          </div>
        </div>
        <!-- End upload template modal box -->

        <!-- Success upload template modal box -->
        <div id="success-upload-template-modal-box" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Bulk Upload Success!</h2>
            </div>
            <div class="uk-modal-body ">
              <div class="uk-flex uk-flex-middle uk-flex-between">
                <p class="uk-margin-remove">All candidate data are correct.<br />There is no incorrect uploaded data.</p>
              </div>
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button
                type="button"
                class="uk-button uk-button-primary uk-modal-close"
              >OK</button>
            </div>
          </div>
        </div>
        <!-- End success upload template modal box -->

        <!-- Failed upload template modal box -->
        <div id="failed-upload-template-modal-box" class="uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Bulk Upload Failed!</h2>
            </div>
            <div class="uk-modal-body ">
              <div class="uk-flex uk-flex-middle uk-flex-between">
                <p class="uk-text-lead uk-margin-remove">Incorrect data</p>
                <template>
                  <button
                    v-if="loadingButtonModalBox"
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded uk-margin-small-left uk-width-1-5"
                    disabled
                  >
                    <span><div uk-spinner="ratio: 0.5"></div></span>
                    <span class="uk-margin-small-left">Loading</span>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="uk-button uk-border-rounded uk-button-primary uk-margin-small-left uk-width-1-5 uk-position-relative"
                    @click="doDownloadIncorrectData"
                  >
                    <img
                      class="uk-position-center-left uk-margin-small-left"
                      :src="`${images}/download-outline-white.svg`"
                      alt="Download icon"
                      width="20"
                    >
                    <span>Download</span>
                  </button>
                </template>
              </div>
              <div class="uk-overflow-auto uk-margin-small-top">
                <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                  <thead class="table-head">
                    <tr>
                      <th class="uk-text-nowrap">No.</th>
                      <th class="uk-text-nowrap">Full Name</th>
                      <th class="uk-text-nowrap">Email</th>
                      <th class="uk-text-nowrap">Phone Number</th>
                      <th class="uk-text-nowrap">Error Status</th>
                      <th class="uk-text-nowrap">Error Message</th>
                      <th class="uk-text-nowrap">Field of Error</th>
                    </tr>
                  </thead>
                  <tbody v-if="incorrectData.length > 0">
                    <tr v-for="(incorrect, index) in incorrectData" :key="index">
                      <td class="uk-text-nowrap">{{ index + 1 }}</td>
                      <td class="uk-text-nowrap uk-text-bold">{{ incorrect.data["Nama Lengkap*"] || '-' }}</td>
                      <td class="uk-text-nowrap">{{ incorrect.data["Email*"] || '-' }}</td>
                      <td class="uk-text-nowrap">{{ incorrect.data["No Telepon*"] }}</td>
                      <td class="uk-text-nowrap">{{ incorrect.status || '-' }}</td>
                      <td class="uk-text-nowrap">{{ incorrect.message || '-' }}</td>
                      <td class="uk-text-nowrap">{{ incorrect.field || '-' }}</td>
                    </tr>
                  </tbody>
                  <empty-table
                    v-else
                    :colspan="7"
                  ></empty-table>
                </table>
              </div>
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button
                type="button"
                class="uk-button"
                :class="[isIncorrectDataDownloaded ? 'uk-button-primary' : 'disabled-button']"
                :disabled="!isIncorrectDataDownloaded"
                @click="hideFailedUploadModalBox"
              >Close</button>
            </div>
          </div>
        </div>
        <!-- End failed upload template modal box -->

      </template>
    </template>
    <no-internet-connection v-else></no-internet-connection>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime';
import { getUserLogin } from '@/utils/auth';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { PREFIX_IMAGE } from '@/utils/constant';
import { debounce, excelDownloader } from "@/utils/helper";

export default {
    name: 'Candidate',
    components: {
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        Loading: () => import('@/components/globals/tables/Loading'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        Pagination: () => import('@/components/globals/Pagination'),
        Datetime
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            internetConnection: true,
            isLoading: false,
            candidateTableLoading: false,
            user_cred: getUserLogin(),
            candidateData: {
                docs: [],
                totalDocs: 0
            },
            meta: {
                email: '',
                phone_number: '',
                fullname: '',
                mitra: false,
                work_experiences: false,
                educations: false,
                skills: false,
                doc_ijazah: false,
                doc_ktp: false,
                doc_skck: false,
                doc_sim: false,
                kormo: false,
                limit: 50,
                page: 1
            },
            teamOptions: ['Community', 'Fulfillment'],
            cityOptions: [],
            jobPreferenceOptions: [],
            roleOptions: [
                { name: 'Candidate', value: ['zxc123'] },
                // {name: 'Employer', value: ['asd123']},
            ],
            partnerOptions: [],
            companyOptions: [],
            companyRoleOptions: [],
            companyOfficeOptions: [],
            selectedUser: {
                id: null,
                email: null,
                phone_number: null,
                password: null,
                fullname: null,
                partner_ids: null,
                roles: null,
                notes_superadmin: null,
                geoloc: {
                    lat: null,
                    lng: null
                },
                verification_code: null,
                user_verified: false,
                company_id: null,
                client_role_id: null,
                client_office_id: null,
                login_via_email: true,
                address: null,
                description: null,
                job_preference_ids: null,
                domicile_city_id: null,
                birthplace_city_id: null,
                birthdate: null
            },
            loadingButtonModalBox: false,
            formFilter: {
                fullname: '',
                phone_number: '',
                email: '',
                educations: false,
                work_experiences: false,
                skills: false,
                doc_skck: false,
                doc_ktp: false,
                doc_sim: false,
                doc_ijazah: false,
                kormo: false
            },
            groupOptions: [],
            inviteGroupForm: {
                user_follower_id: null,
                group_id: null
            },
            emailAlreadyExistValidator: false,
            uploadedTemplateTeam: null,
            incorrectData: [],
            filenameIncorrectExcel: null,
            isIncorrectDataDownloaded: false,
            passwordErrors: {
                hasUpperCase: false,
                hasLowerCase: false,
                minimalChars: false
            }
        };
    },
    watch: {
        async meta() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.candidateTableLoading = true;
                    await this.setCandidateData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.candidateTableLoading = false;
            }
        },
        'selectedUser.password'(nVal){
            const regexUpper = /([A-Z])/g;
            const regexLower = /([a-z])/g;
            const hasUpperCase = regexUpper.test(nVal);
            const hasLowerCase = regexLower.test(nVal);
            const minimalChars = nVal.length >= 8 || false; 
            this.passwordErrors.hasUpperCase = hasUpperCase;
            this.passwordErrors.hasLowerCase = hasLowerCase;
            this.passwordErrors.minimalChars = minimalChars;
        }
    },
    computed: {
        ...mapGetters({
            users: 'user/users',
            companies: 'company/companies',
            company: 'company/company',
            company_roles: 'company_role/company_roles',
            properties: 'property/properties',
        }),
        disabledCandidateFilterSearchButton() {
            let disabled = true;

            if (
                this.formFilter.fullname === '' &&
          this.formFilter.phone_number === '' &&
          this.formFilter.email === '' &&
          this.formFilter.educations === false &&
          this.formFilter.work_experiences === false &&
          this.formFilter.skills === false &&
          this.formFilter.doc_skck === false &&
          this.formFilter.doc_ktp === false &&
          this.formFilter.doc_sim === false &&
          this.formFilter.doc_ijazah === false &&
          this.formFilter.kormo === false
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        disabledCreateOrEditCandidateSaveButton() {
            let disabled = true;

            if (
                this.selectedUser.fullname === null ||
                this.selectedUser.birthplace_city_id === null ||
                this.selectedUser.birthdate === null ||
                this.selectedUser.domicile_city_id === null ||
                this.selectedUser.address === null ||
                this.emailAlreadyExistValidator === true ||
                this.$validator.errors.any('selectedUser') ||
                this.passwordErrors.minimalChars === false ||
                this.passwordErrors.hasUpperCase === false ||
                this.passwordErrors.hasLowerCase === false
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        disabledInviteGroupSaveButton() {
            let disabled = true;

            if (
                this.inviteGroupForm.user_follower_id === null ||
          this.inviteGroupForm.group_id === null ||
          this.$validator.errors.any('inviteGroupForm')
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        filterText() {
            let total = 0;
            if (this.meta.fullname.length > 0) {
                total++;
            }
            if (this.meta.email.length > 0) {
                total++;
            }
            if (this.meta.phone_number.length > 0) {
                total++;
            }
            if (this.meta.educations == true) {
                total++;
            }
            if (this.meta.work_experiences == true) {
                total++;
            }
            if (this.meta.skills == true) {
                total++;
            }
            if (this.meta.doc_ijazah == true) {
                total++;
            }
            if (this.meta.doc_ktp == true) {
                total++;
            }
            if (this.meta.doc_skck == true) {
                total++;
            }
            if (this.meta.doc_sim == true) {
                total++;
            }
            if (this.meta.kormo == true) {
                total++;
            }
            if (total!=0) {
                return `${total} `;
            } else {
                return '';
            }
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-candidate-modal-box')) window.UIkit.modal('.create-candidate-modal-box').$destroy(true);
        if (window.UIkit.modal('.delete-candidate-modal-box')) window.UIkit.modal('.delete-candidate-modal-box').$destroy(true);
        if (window.UIkit.modal('.invite-candidate-modal-box')) window.UIkit.modal('.invite-candidate-modal-box').$destroy(true);
        if (window.UIkit.modal('#upload-template-modal-box')) window.UIkit.modal('#upload-template-modal-box').$destroy(true);
        if (window.UIkit.modal('#success-upload-template-modal-box')) window.UIkit.modal('#success-upload-template-modal-box').$destroy(true);
        if (window.UIkit.modal('#failed-upload-template-modal-box')) window.UIkit.modal('#failed-upload-template-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.isLoading = true;
                await this.setCandidateData();
                await this.setJobPreferenceOptions();
            } else {
                this.internetConnection = false;
            }
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getUserCandidate: 'user/getUserCandidate',
            createUser: 'user/createUser',
            updateUser: 'user/updateUser',
            deleteUser: 'user/deleteUser',
            bulkUploadCandidate: 'user/bulkUploadCandidate',
            getCompanies: 'company/getCompanies',
            getCompanyDetail: 'company/getCompanyDetail',
            getCompanyRoles: 'company_role/getCompanyRoles',
            getProperties: 'property/getProperties',
            getReportUser: 'user/getReportUser',
            getCity: 'option_data/getCity',
            getGroup: 'option_data/getGroup',
            getAllJobPreferenceList: 'job_preference/getAllJobPreferenceList',
            postInviteUser: 'option_data/postInviteUser',
        }),
        async setCityOptions(searchQuery) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.cityOptions = await this.getCity({ name: searchQuery });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setJobPreferenceOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.jobPreferenceOptions = await this.getAllJobPreferenceList();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setPartnerOptions(searchQuery) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getCompanies({ name: searchQuery });
                    if (this.companies && this.companies.docs.length > 0) {
                        this.partnerOptions = this.companies.docs.map((item) => ({
                            name: item.name, value: item._id
                        }));
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setCompanyOptions(searchQuery) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getCompanies({ name: searchQuery });
                    if (this.companies && this.companies.docs.length > 0) {
                        this.companyOptions = this.companies.docs.map((item) => ({
                            name: item.name, value: item._id
                        }));
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setCompanyRoleOptions(searchQuery) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getCompanyRoles({ name: searchQuery, is_internal: true });
                    if (this.company_roles && this.company_roles.docs.length > 0) {
                        this.companyRoleOptions = this.company_roles.docs.map((item) => ({
                            name: item.name, value: item._id
                        }));
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setCompanyOfficeOptions(searchQuery) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getProperties({ name: searchQuery });
                    if (this.properties && this.properties.docs.length > 0) {
                        this.companyOfficeOptions = this.properties.docs.map((item) => ({
                            name: item.name, value: item._id
                        }));
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setGroupOptions(searchQuery) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    const response = await this.getGroup({ name: searchQuery, user_id: this.inviteGroupForm.user_follower_id });
                    if (response && response.length > 0) {
                        const groupOptions = response.map((item) => ({ name: item.name, value: item._id }));
                        this.groupOptions = groupOptions;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setCandidateData() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getUserCandidate(this.meta);
                    if (this.users) {
                        this.candidateData.docs = this.users.docs;
                        this.candidateData.totalDocs = this.users.totalDocs;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        triggerUploadTemplate() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$refs.templateFile.click();
            } else {
                this.internetConnection = false;
            }
        },
        async showCreateCandidateModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetSelectedUser();
                    await window.UIkit.modal('#create-candidate-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showDeleteCandidateModalBox({ id, fullname, email, phoneNumber }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetSelectedUser();
                    this.selectedUser.id = id;
                    this.selectedUser.fullname = fullname;
                    this.selectedUser.email = email;
                    this.selectedUser.phone_number = phoneNumber;
                    await window.UIkit.modal('#delete-candidate-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showInviteCandidateModalBox({ id }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetInviteGroupForm();
                    this.inviteGroupForm.user_follower_id = id;
                    await window.UIkit.modal('#invite-candidate-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showUploadTemplateModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await window.UIkit.modal('#upload-template-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideUploadTemplateModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.uploadedTemplateTeam = null;
                    await window.UIkit.modal('#upload-template-modal-box').hide();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideFailedUploadModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await window.UIkit.modal('#failed-upload-template-modal-box').hide();
                    this.incorrectData = [];
                    this.filenameIncorrectExcel = null;
                    this.isIncorrectDataDownloaded = false;
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateCandidate() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const validate = await this.$validator.validateAll('selectedUser');
                    if (!validate || this.$validator.errors.any('selectedUser')) return;

                    this.loadingButtonModalBox = true;

                    const payloads = {
                        email: this.selectedUser.email,
                        phone_number: this.selectedUser.phone_number,
                        password: this.selectedUser.password,
                        fullname: this.selectedUser.fullname,
                        partner_ids: [],
                        roles: ['zxc123'], // set default as candidate code roles
                        notes_superadmin: this.selectedUser.notes_superadmin,
                        user_verified: this.selectedUser.user_verified,
                        login_via_email: this.selectedUser.login_via_email,
                        address: this.selectedUser.address,
                        description: this.selectedUser.description,
                        job_preference_ids: [],
                        domicile_city_id: null,
                        birthplace_city_id: null,
                        birthdate: this.selectedUser.birthdate
                    };

                    if (this.selectedUser.partner_ids && this.selectedUser.partner_ids.length > 0) {
                        const partnerIds = this.selectedUser.partner_ids.map((item) => item.value);
                        payloads.partner_ids = partnerIds;
                    }

                    if (this.selectedUser.roles && this.selectedUser.roles.value !== null) {
                        payloads.roles = this.selectedUser.roles.value;
                    }

                    if (this.selectedUser.job_preference_ids && this.selectedUser.job_preference_ids.length > 0) {
                        const jobPreferenceIds = this.selectedUser.job_preference_ids.map((item) => item._id);
                        payloads.job_preference_ids = jobPreferenceIds;
                    }

                    if (this.selectedUser.domicile_city_id && this.selectedUser.domicile_city_id._id) {
                        payloads.domicile_city_id = this.selectedUser.domicile_city_id._id;
                    }

                    if (this.selectedUser.birthplace_city_id && this.selectedUser.birthplace_city_id._id) {
                        payloads.birthplace_city_id = this.selectedUser.birthplace_city_id._id;
                    }

                    const response = await this.createUser(payloads);
                    if (response && response.status === 'OK') {
                        this.resetSelectedUser();
                        await window.UIkit.modal('#create-candidate-modal-box').hide();
                        notificationSuccess('User saved!');
                        this.candidateTableLoading = true;
                        await this.setCandidateData();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingButtonModalBox = false;
                this.candidateTableLoading = false;
            }
        },
        async doUploadTemplate(e) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    this.loadingButtonModalBox = true;
                    const filepath = e.target.files[0];
                    let data = new FormData();
                    data.append('team', this.uploadedTemplateTeam);
                    data.append('excel', filepath);
                    const response = await this.bulkUploadCandidate(data);
                    if (response && response.status === 'OK') {
                        this.hideUploadTemplateModalBox();
                        this.loadingButtonModalBox = false;
                        if (response.incorrect_data.length > 0) {
                            this.filenameIncorrectExcel = filepath.name;
                            this.incorrectData = response.incorrect_data;
                            await window.UIkit.modal('#failed-upload-template-modal-box').show();
                        } else {
                            await window.UIkit.modal('#success-upload-template-modal-box').show();
                        }
                    } else {
                        notificationDanger('Failed to upload data!');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingButtonModalBox = false;
            }
        },
        doDownloadTemplate() {
            const dataExcel = [];
            const obj = {
                "Email*": '',
                "No Telepon*": '',
                "Password*": '',
                "Nama Lengkap*": '',
                "Domisili*": '',
                "Jenis Kelamin*": '',
                "Alamat Lengkap": '',
                "Tempat Lahir": '',
                "Tanggal Lahir": '',
                "Keahlian": '',
                "Nomor Induk Kependudukan (NIK) (16 digit angka)": '',
            };
            dataExcel.push(obj);
            excelDownloader(dataExcel, 'Talent Pool_Bulk Upload_Community & Fulfillment.xlsx');
            notificationSuccess('Downloaded!');
        },
        doDownloadIncorrectData() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.loadingButtonModalBox = true;

                    const incorrectData = this.incorrectData.map(incorrect => incorrect.data);
                    const fileName = `(Incorrect data) ${this.filenameIncorrectExcel}`;
                    excelDownloader(incorrectData, fileName);
                    notificationSuccess('Incorrect Data successfully downloaded');
                    this.isIncorrectDataDownloaded = true;
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingButtonModalBox = false;
            }
        },
        async doDeleteCandidate() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    this.loadingButtonModalBox = true;

                    const response = await this.deleteUser(this.selectedUser.id);
                    if (response && response.status === 'OK') {
                        this.resetSelectedUser();
                        await window.UIkit.modal('#delete-candidate-modal-box').hide();
                        notificationSuccess('User Deleted!');
                        this.candidateTableLoading = true;
                        await this.setCandidateData();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingButtonModalBox = false;
                this.candidateTableLoading = false;
            }
        },
        async doInviteCandidate() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const validate = await this.$validator.validateAll('inviteGroupForm');
                    if (!validate || this.$validator.errors.any('inviteGroupForm')) return;

                    this.loadingButtonModalBox = true;

                    const payloads = {
                        user_follower_id: this.inviteGroupForm.user_follower_id,
                        group_id: null
                    };

                    if (this.inviteGroupForm.group_id && this.inviteGroupForm.group_id.value) {
                        payloads.group_id = this.inviteGroupForm.group_id.value;
                    }

                    const response = await this.postInviteUser(payloads);
                    if (response && response.status === 'OK') {
                        this.resetInviteGroupForm();
                        await window.UIkit.modal('#invite-candidate-modal-box').hide();
                        notificationSuccess('User Invited!');
                        this.candidateTableLoading = true;
                        await this.setCandidateData();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.loadingButtonModalBox = false;
                this.candidateTableLoading = false;
            }
        },
        async setFilter() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await window.UIkit.dropdown('#dropdown-filter').hide(false);

                    this.fillMeta({
                        email: this.formFilter.email,
                        phoneNumber: this.formFilter.phone_number,
                        fullname: this.formFilter.fullname,
                        mitra: false,
                        workExperiences: this.formFilter.work_experiences,
                        educations: this.formFilter.educations,
                        skills: this.formFilter.skills,
                        docIjazah: this.formFilter.doc_ijazah,
                        docKtp: this.formFilter.doc_ktp,
                        docSkck: this.formFilter.doc_skck,
                        docSim: this.formFilter.doc_sim,
                        kormo: this.formFilter.kormo
                    });
                    this.candidateTableLoading = true;
                    await this.setCandidateData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.candidateTableLoading = false;
            }
        },
        async resetFilter() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await window.UIkit.dropdown('#dropdown-filter').hide(false);
                    this.resetFormFilter();
                    this.resetMeta();
                    this.candidateTableLoading = true;
                    await this.setCandidateData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.candidateTableLoading = false;
            }
        },
        findCandidateByEmail: debounce(async function() {
            try {
                this.emailAlreadyExistValidator = false;
                this.fillMeta({
                    email: this.selectedUser.email,
                    phoneNumber: '',
                    fullname: '',
                    mitra: false,
                    workExperiences: false,
                    educations: false,
                    skills: false,
                    docIjazah: false,
                    docKtp: false,
                    docSkck: false,
                    docSim: false,
                    kormo: false
                });
                await this.setCandidateData();

                if (this.candidateData.docs.length > 0) {
                    const findCandidate = this.candidateData.docs.find((data) => (data && data.email) && (this.selectedUser.email) && (data.email === this.selectedUser.email));
                    if (findCandidate) {
                        this.emailAlreadyExistValidator = true;
                    }
                }
            } catch (error) {
                notificationDanger(error);
            }
        }, 200),
        onCopy(e) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                notificationSuccess('Copied to clipboard: ' + e.text);
            } else {
                this.internetConnection = false;
            }
        },
        candidateDetail({ id }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$router.push(`/admin/candidate/detail/${id}`);
            } else {
                this.internetConnection = false;
            }
        },
        fillMeta({ email, phoneNumber, fullname, mitra, workExperiences, educations, skills, docIjazah, docKtp, docSkck, docSim, kormo }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.meta.email = email;
                this.meta.phone_number = phoneNumber;
                this.meta.fullname = fullname;
                this.meta.mitra = mitra;
                this.meta.work_experiences = workExperiences;
                this.meta.educations = educations;
                this.meta.skills = skills;
                this.meta.doc_ijazah = docIjazah;
                this.meta.doc_ktp = docKtp;
                this.meta.doc_skck = docSkck;
                this.meta.doc_sim = docSim;
                this.meta.kormo = kormo;
                this.meta.limit = 50;
                this.meta.page = 1;
            } else {
                this.internetConnection = false;
            }
        },
        resetInviteGroupForm() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.inviteGroupForm.user_follower_id = null;
                this.inviteGroupForm.group_id = null;
                this.$validator.reset();
            } else {
                this.internetConnection = false;
            }
        },
        resetFormFilter() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.formFilter.fullname = '';
                this.formFilter.phone_number = '';
                this.formFilter.email = '';
                this.formFilter.educations = false;
                this.formFilter.work_experiences = false;
                this.formFilter.skills = false;
                this.formFilter.doc_skck = false;
                this.formFilter.doc_ktp = false;
                this.formFilter.doc_sim = false;
                this.formFilter.doc_ijazah = false;
                this.formFilter.kormo = false;
            } else {
                this.internetConnection = false;
            }
        },
        resetMeta() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.meta.email = '';
                this.meta.phone_number = '';
                this.meta.fullname = '';
                this.meta.mitra = false;
                this.meta.work_experiences = false;
                this.meta.educations = false;
                this.meta.skills = false;
                this.meta.doc_ijazah = false;
                this.meta.doc_ktp = false;
                this.meta.doc_skck = false;
                this.meta.doc_sim = false;
                this.meta.kormo = false;
                this.meta.limit = 50;
                this.meta.page = 1;
            } else {
                this.internetConnection = false;
            }
        },
        resetSelectedUser() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.selectedUser.email = null;
                this.selectedUser.phone_number = null;
                this.selectedUser.password = null;
                this.selectedUser.fullname = null;
                this.selectedUser.partner_ids = null;
                this.selectedUser.roles = null;
                this.selectedUser.notes_superadmin = null;
                this.selectedUser.geoloc.lat = null;
                this.selectedUser.geoloc.lng = null;
                this.selectedUser.verification_code = null;
                this.selectedUser.user_verified = false;
                this.selectedUser.company_id = null;
                this.selectedUser.client_role_id = null;
                this.selectedUser.client_office_id = null;
                this.selectedUser.login_via_email = true;
                this.selectedUser.address = null;
                this.selectedUser.description = null;
                this.selectedUser.job_preference_ids = null;
                this.selectedUser.domicile_city_id = null;
                this.selectedUser.birthplace_city_id = null;
                this.selectedUser.birthdate = null;
                this.$validator.reset();
            } else {
                this.internetConnection = false;
            }
        },
        changeLimit(e) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.meta = {
                    ...this.meta,
                    limit: e.target.value
                };
            } else {
                this.internetConnection = false;
            }
        },
        changePage(value) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.meta = {
                    ...this.meta,
                    page: value
                };
            } else {
                this.internetConnection = false;
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        }
    }
};
</script>

<style scoped>
    .disabled-button {
        color: #FFFF;
        background-color: #9999;
        border: 1px solid #9999;
        background-clip: padding-box;
    }

    .table-head {
        background-color: #4285E9;
    }

    .table-head tr th {
        font-weight: 600;
        color: white;
    }

    .text-#FF0000 {
        color: #FF0000 !important;
    }
</style>
